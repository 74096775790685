import React from 'react'

import { Layout } from 'components/global'
import { MusicPlayDayHeader, Social } from 'components/blocks'
import { MusicPlayDay } from 'components/blocks/MusicPlayDay'

const MusicPlayDayPage = () => (
  <Layout pageName="music-play-day-page">
    <MusicPlayDayHeader />
    <div className="w-[90%] mx-auto max-w-[1400px] mt-6">
      <section className="lg:grid lg:grid-cols-2 lg:py-6 lg:gap-6">
        <div>
          <p>
            Aladdin Music Play Day is your chance to experience what it&rsquo;s
            like to play the spectacular music from the award-winning musical.
          </p>
          <p>
            This is your chance to work side by side with our musicians from
            Aladdin touring production. Members of the orchestra will give
            instrument specific masterclasses throughout the day and help you
            work towards a brief performance at the end of the day.&nbsp;
          </p>
          <p>
            Our aim is to inspire and encourage students who enjoy playing an
            instrument and give them an insight into our daily work as
            professional musicians.&nbsp;
          </p>
        </div>
        <div>
          <p className="text-purple">
            <strong>Key information you need to know</strong>
          </p>
          <ul className="list-disc pl-[2rem]">
            <li>Aladdin Music Play Day</li>
            <li>Tuesday 20th August 2024 10:00am – 3:30pm (approx.)</li>
            <li>Alhambra Theatre, Morley Street Bradford, BD7 1AJ</li>
            <li>
              The closing date for applications is Thursday 11th July 10am
            </li>
          </ul>
        </div>
      </section>
      <div className="">
        <p className="text-center py-12 text-[26px]">
          <br />
          Applications for Music Play Day 2024 have now closed.
        </p>
      </div>
    </div>

    {/* <MusicPlayDay /> */}
    <Social />
  </Layout>
)

export default MusicPlayDayPage
