import React, { Component } from 'react'
import ReactDOM from 'react-dom'
// import { ReCaptcha } from 'recaptcha-v3-react'
import { navigate } from 'gatsby'
import { Amplify, API } from 'aws-amplify'
import Cookies from 'js-cookie'
import { Container } from 'components/global'
import {
  validateRequired,
  validateEmail,
  validatePhone,
  // validateAmount,
  validateRequiredCheckbox,
} from 'js/validations'

import awsconfig from '../../../aladdin-aws-exports.js'

const captchaSiteKey = '6Le95OonAAAAALI1AkQxgkES39TPGdFtFGh3K5kU'

Amplify.configure(awsconfig)

class MusicPlayDayForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tutorName: '',
      tutorEmail: '',
      questions: '',
      fullName: '',
      email: '',
      consent: '',
      instrument: '',
      phoneNumber: '',
      grade: '',
      accessRequirements: '',
      googleVerified: '',
      validate: {
        fullName: '',
        email: '',
        phoneNumber: '',
        instrument: '',
        grade: '',
        accessRequirements: '',
        googleVerified: '',
        emailErrMsg: 'A valid email is required',
        phoneErrMsg: 'A valid UK phone number is required',
        googleVerifiedErrMsg: 'Google recaptcha is required',
      },
      success: false,
      submitted: false,
      cookieAccepted: false,
    }

    // Bind this to methods
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onGoogleVerify = this.onGoogleVerify.bind(this)

    // Bind this to validation methods
    this.validateRequired = validateRequired.bind(this)
    this.validateEmail = validateEmail.bind(this)
    this.validatePhone = validatePhone.bind(this)
    // this.validateAmount = validateAmount.bind(this)
    this.validateRequiredCheckbox = validateRequiredCheckbox.bind(this)

    // Create form ref
    this.form = React.createRef()
  }

  componentDidMount() {
    if (
      Cookies.get('TWDC-DTCI_ENSIGHTEN_PRIVACY_TWDC_Vendor_Consent') !== '1'
    ) {
      this.setState({
        cookieAccepted: true,
      })
    }

    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${captchaSiteKey}`
    script.addEventListener('load', () => {
      console.log('window.grecaptcha: loaded')
      const that = this
      if (typeof window.grecaptcha !== 'undefined') {
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise
            .execute(captchaSiteKey, { action: 'ourfirstshow' })
            .then(token => {
              // console.log(token)
              that.onGoogleVerify(token)
            })
          // refresh token every minute to prevent expiration
          setInterval(() => {
            window.grecaptcha.enterprise
              .execute(captchaSiteKey, { action: 'ourfirstshow' })
              .then(token => {
                that.onGoogleVerify(token)
              })
          }, 60000)
        })
      }
    })
    document.body.appendChild(script)
  }

  handleChangeNumbers = (evt, stateName, stateValue) => {
    const value = evt.target.validity.valid ? evt.target.value : stateValue

    this.setState({ [stateName]: value })
  }

  // Method to update field values in state on change
  handleChange(e) {
    const { target } = e
    const { name } = target
    const value = target.type === 'checkbox' ? target.checked : target.value

    // Update value in state
    this.setState({
      [name]: value,
    })
  }

  // Method to handle form submit
  async handleSubmit(e) {
    e.preventDefault()

    // Create form data ready for api wrapper call
    const formData = new FormData()

    for (const key in this.state) {
      if (
        typeof this.state[key] === 'object' &&
        this.state[key].constructor === Object
      ) {
        for (const k in this.state[key]) {
          formData[`${key}[${k}]`] = this.state[key][k]
          // formData.append(key + '[' + k + ']', this.state[key][k])
        }
      } else {
        formData[key] = this.state[key]
        // formData.append(key, this.state[key]);
      }
    }

    console.log(formData)

    const apiName = 'ourFirstShowAladdinAPI'
    const path = '/musicPlayDay'
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: formData,
    }

    return API.get(apiName, path, myInit)
      .then(result => {
        if (result.status !== 200) {
          throw Error(result.statusText) // catch any server errors
        }
        return result
      })
      .then(response => {
        console.log(response)
        const { event } = response.data
        this.setState({
          submitted: true,
          success: true,
          submitBtnCopy: 'Sending form',
        })
        // If there are errors update validation state
        if (
          typeof event.errors !== 'undefined' &&
          Object.keys(event.errors).length > 0
        ) {
          const { validate } = this.state

          for (const key in event.errors) {
            if (event.errors[key] === true) {
              validate[key] = 'has-danger'
            } else {
              validate[key] = event.errors[key]
            }

            this.setState({ validate })
          }

          // re-enable the button
          this.setState({
            submitted: false,
            sendingFormRequest: false,
            submitBtnCopy: 'Submit',
          })
          // Scroll top top of form
          const domNode = ReactDOM.findDOMNode(this.form.current)
          window.scrollTo({
            top: domNode.offsetTop,
            behavior: 'smooth',
          })
        } else if (typeof event.MessageId !== 'undefined') {
          navigate('/thank-you')
        } else {
          console.log(event.code)
        }
      })
      .catch(error => {
        this.setState({ sendingFormRequest: false })
        console.log(error)
        return error.response
      })
  }

  async onGoogleVerify(response) {
    console.log('GR')
    const { validate } = this.state
    const myInit = {
      headers: {},
      body: {
        recaptchaResponse: response,
      },
    }
    const res = await API.post(
      'ourFirstShowAladdinAPI',
      '/verifyRecaptcha',
      myInit
    )
      .then(response => {
        // If there are errors update validation state
        console.log(response)
        if (response.event.success === false || response.event < 0.5) {
          validate.googleVerified = 'has-danger'
          console.log('FAILED')
          this.setState({
            validate,
            googleVerified: false,
          })
          throw Error('Failed recaptcha')
        } else {
          // success
          console.log('SUCCESS')
          validate.googleVerified = ''
          this.setState({
            validate,
            googleVerified: true,
          })
        }
      })
      .catch(error => {
        console.log('caught error', error)
        // handle errors and timeout error
        validate.googleVerified = 'has-danger'
        this.setState({
          validate,
          googleVerified: false,
        })
      })
  }

  openDialog = e => {
    if (
      typeof window !== 'undefined' &&
      typeof window.__dataLayer !== 'undefined'
    ) {
      window.__dataLayer.OneTrustDialog(e)
    }
  }

  render() {
    // TWDC-DTCI_ENSIGHTEN_PRIVACY_TWDC_Vendor_Consent
    return (
      <Container padding>
        <div className="text-purple">
          {Cookies.get('TWDC-DTCI_ENSIGHTEN_PRIVACY_TWDC_Vendor_Consent') !==
          '1' ? (
            <div className="text-center">
              <p>
                <strong>
                  Note: To be able to see the form below and submit your
                  details, you must accept the cookies.
                </strong>
              </p>
              <strong>
                <a
                  className="no-interstitial-check btn--red btn--block"
                  onClick={e => this.openDialog(e)}
                  href="#"
                  role="button"
                >
                  Manage Your Cookie Settings
                </a>
              </strong>
            </div>
          ) : (
            <>
              <form
                onSubmit={e => this.handleSubmit(e)}
                noValidate
                ref={this.form}
              >
                <div className="">
                  <h4 className="mb-1 px-4 text-[25px]">
                    Music Tutor/Parent/Teacher{' '}
                  </h4>
                  <div className="grid md:md:grid-cols-[50%,_1fr]">
                    <div className="px-4">
                      <label
                        className="block uppercase tracking-wide text-purple text-xs font-bold mb-2"
                        htmlFor="tutorName"
                      >
                        Full Name
                      </label>
                      <input
                        className={`${
                          this.state.validate.tutorName === 'has-danger'
                            ? 'border-red-500'
                            : ''
                        } ${
                          this.state.validate.tutorName === 'has-success'
                            ? 'border-green-500'
                            : ''
                        } appearance-none block w-full text-purple border-2 border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        onChange={e => {
                          this.handleChange(e)
                          //   this.validateRequired(e)
                        }}
                        value={this.state.tutorName}
                        type="text"
                        name="tutorName"
                        id="tutorName"
                      />
                    </div>
                    <div className="px-4">
                      <label
                        className="block uppercase tracking-wide text-purple text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        className={`${
                          this.state.validate.tutorEmail === 'has-danger'
                            ? 'border-red-500'
                            : ''
                        } ${
                          this.state.validate.tutorEmail === 'has-success'
                            ? 'border-green-500'
                            : ''
                        } appearance-none block w-full text-purple border-2 border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        onChange={e => {
                          this.handleChange(e)
                          //   this.validateRequired(e)
                          //   this.validateEmail(e)
                        }}
                        value={this.state.tutorEmail}
                        type="tutorEmail"
                        name="tutorEmail"
                        id="tutorEmail"
                      />
                      {this.state.validate.tutorEmail === 'has-danger' && (
                        <p className="text-red-500 text-xs italic">
                          {this.state.validate.emailErrMsg}
                        </p>
                      )}
                    </div>
                  </div>
                  <h4 className="mb-1 px-4 text-[25px] mt-4">
                    Participant details
                  </h4>
                  <div className="grid md:md:grid-cols-[50%,_1fr]">
                    <div className="px-4">
                      <label
                        className="block uppercase tracking-wide text-purple text-xs font-bold mb-2"
                        htmlFor="fullName"
                      >
                        Full Name*
                      </label>
                      <input
                        className={`${
                          this.state.validate.fullName === 'has-danger'
                            ? 'border-red-500'
                            : ''
                        } ${
                          this.state.validate.fullName === 'has-success'
                            ? 'border-green-500'
                            : ''
                        } appearance-none block w-full text-purple border-2 border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        onChange={e => {
                          this.handleChange(e)
                          this.validateRequired(e)
                        }}
                        value={this.state.fullName}
                        type="text"
                        name="fullName"
                        id="fullName"
                      />
                      {this.state.validate.fullName === 'has-danger' && (
                        <p className="text-red-500 text-xs italic">
                          Full Name is required
                        </p>
                      )}
                    </div>
                    <div className="px-4">
                      <label
                        className="block uppercase tracking-wide text-purple text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email (if under 18 please enter parent/guardian email)*
                      </label>
                      <input
                        className={`${
                          this.state.validate.email === 'has-danger'
                            ? 'border-red-500'
                            : ''
                        } ${
                          this.state.validate.email === 'has-success'
                            ? 'border-green-500'
                            : ''
                        } appearance-none block w-full text-purple border-2 border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        onChange={e => {
                          this.handleChange(e)
                          this.validateRequired(e)
                          this.validateEmail(e)
                        }}
                        value={this.state.email}
                        type="email"
                        name="email"
                        id="email"
                      />
                      {this.state.validate.email === 'has-danger' && (
                        <p className="text-red-500 text-xs italic">
                          Please enter a valid email
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid md:md:grid-cols-[50%,_1fr]">
                    <div className="px-4">
                      <label
                        className="block uppercase tracking-wide text-purple text-xs font-bold mb-2"
                        htmlFor="phoneNumber"
                      >
                        Phone number*
                      </label>
                      <input
                        className={`${
                          this.state.validate.phoneNumber === 'has-danger'
                            ? 'border-red-500'
                            : ''
                        } ${
                          this.state.validate.phoneNumber === 'has-success'
                            ? 'border-green-500'
                            : ''
                        } appearance-none block w-full text-purple border-2 border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                        onChange={e => {
                          this.handleChange(e)
                          this.validateRequired(e)
                          this.validatePhone(e, 'phoneNumber', true)
                        }}
                        value={this.state.phoneNumber}
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                      />
                      {this.state.validate.phoneNumber === 'has-danger' && (
                        <p className="text-red-500 text-xs italic">
                          {this.state.validate.phoneErrMsg}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="px-4 flex flex-row-reverse justify-end gap-4 mt-3">
                    <label
                      className={`
                        ${
                          this.state.validate.consent === 'has-danger' &&
                          '!text-red-500'
                        }
                      block uppercase tracking-wide text-purple text[16px]  font-bold mb-2`}
                      htmlFor="consent"
                    >
                      If participant is under 18, I give my consent to the named
                      above to take part in Music play day.
                    </label>
                    <input
                      type="checkbox"
                      className={`${
                        this.state.validate.consent === 'has-danger'
                          ? 'border-red-500'
                          : ''
                      } ${
                        this.state.validate.consent === 'has-success'
                          ? 'border-green-500'
                          : ''
                      }  block border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      onChange={e => {
                        this.handleChange(e)
                        this.validateRequiredCheckbox(e)
                      }}
                      value={this.state.consent}
                      name="consent"
                      id="consent"
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-[50%,_1fr]">
                  <div className="px-4">
                    <label
                      className="block uppercase tracking-wide text-purple text-xs font-bold mb-2"
                      htmlFor="instrument"
                    >
                      Instrument*
                    </label>
                    <select
                      className={`${
                        this.state.validate.instrument === 'has-danger'
                          ? 'border-red-500'
                          : ''
                      } ${
                        this.state.validate.instrument === 'has-success'
                          ? 'border-green-500'
                          : ''
                      } appearance-none block w-full text-purple border-2 border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      onChange={e => {
                        this.handleChange(e)
                        this.validateRequired(e)
                      }}
                      value={this.state.instrument}
                      name="instrument"
                      id="instrument"
                    >
                      <option value="">Select</option>
                      <option value="drums">Drums</option>
                      <option value="violin">Violin</option>
                      <option value="viola">Viola</option>
                      <option value="cello">Cello</option>
                      <option value="french_horn">French Horn</option>
                      <option value="trombone">Trombone</option>
                      <option value="trumpet">Trumpet</option>
                      <option value="guitar">Guitar</option>
                      <option value="bass_guitar">Bass Guitar</option>
                      <option value="flute">Flute</option>
                      <option value="clarinet">Clarinet</option>
                      <option value="sax">Sax</option>
                      <option value="keyboard">Keyboard</option>
                    </select>
                    {this.state.validate.instrument === 'has-danger' && (
                      <p className="text-red-500 text-xs italic">
                        Instrument is required
                      </p>
                    )}
                  </div>
                  <div className="px-4">
                    <label
                      className="block uppercase tracking-wide text-purple text-xs font-bold mb-2"
                      htmlFor="grade"
                    >
                      Grade* (Must Be Grade 5 Plus)
                    </label>
                    <input
                      className={`${
                        this.state.validate.grade === 'has-danger'
                          ? 'border-red-500'
                          : ''
                      } ${
                        this.state.validate.grade === 'has-success'
                          ? 'border-green-500'
                          : ''
                      } appearance-none block w-full text-purple border-2 border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      onChange={e => {
                        this.handleChange(e)
                        this.validateRequired(e)
                      }}
                      value={this.state.grade}
                      type="text"
                      name="grade"
                      id="grade"
                    />
                    {this.state.validate.grade === 'has-danger' && (
                      <p className="text-red-500 text-xs italic">
                        Grade is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-[100%,_1fr]">
                  <div className="px-4">
                    <label
                      className="block uppercase tracking-wide text-purple text-xs font-bold mb-2"
                      htmlFor="questions"
                    >
                      Do you have any questions you would like to ask our
                      musicians on the day?
                    </label>
                    <textarea
                      rows="5"
                      className={` appearance-none block w-full text-purple border-2 border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      onChange={e => {
                        this.handleChange(e)
                        // this.validateRequired(e)
                      }}
                      value={this.state.questions}
                      name="questions"
                      id="questions"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-[100%,_1fr]">
                  <div className="px-4">
                    <label
                      className="block uppercase tracking-wide text-purple text-xs font-bold mb-2"
                      htmlFor="accessRequirements"
                    >
                      Please let us know if you have any access requirements*
                      (Can be n/a)
                    </label>
                    <textarea
                      rows="5"
                      className={`${
                        this.state.validate.accessRequirements === 'has-danger'
                          ? 'border-red-500'
                          : ''
                      } ${
                        this.state.validate.accessRequirements === 'has-success'
                          ? 'border-green-500'
                          : ''
                      } appearance-none block w-full text-purple border-2 border-purple text-purple border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      onChange={e => {
                        this.handleChange(e)
                        this.validateRequired(e)
                      }}
                      value={this.state.accessRequirements}
                      name="accessRequirements"
                      id="accessRequirements"
                    />
                    {this.state.validate.accessRequirements ===
                      'has-danger' && (
                      <p className="text-red-500 text-xs italic">
                        Access requirements is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="px-4">
                  <p>
                    The information provided in this application form will be
                    subject to Disney&rsquo;s Privacy Policy and will be used
                    solely for the purpose of assessing your application.
                  </p>
                  <p>
                    Disclaimer: by applying for these places I am committing to
                    being able to attend/bring group
                  </p>
                </div>
                <div className="grid grid-cols-[100%,_1fr]">
                  <div className="px-4 text-center my-6 lg:my-12">
                    {this.state.validate.googleVerified === 'has-danger' ? (
                      <button
                        data-sitekey={captchaSiteKey}
                        data-action="submit"
                        // onClick={e => this.handleSubmit(e)}
                        type="submit"
                        disabled
                        className="w-full max-w-[300px] rounded-[25px] bg-red-500 text-white py-4 px-12 uppercase hover:bg-gold transition-all hover:text-black"
                      >
                        Capcha Failed
                      </button>
                    ) : (
                      <button
                        data-sitekey={captchaSiteKey}
                        data-action="submit"
                        // onClick={e => this.handleSubmit(e)}
                        type="submit"
                        disabled={this.state.submitted === true}
                        className={`${
                          this.state.submitted === true && 'cursor-wait	'
                        } w-full max-w-[300px] rounded-[25px] bg-purple text-white py-4 px-12 uppercase hover:bg-gold transition-all hover:text-black`}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </form>
              <div className="px-4">
                <p>
                  <strong>Terms &amp; Conditions</strong>
                </p>
                <p>
                  Please email any queries to{' '}
                  <a href="mailto:theatreeducationuk@disney.co.uk">
                    theatreeducationuk@disney.co.uk
                  </a>{' '}
                  &copy;Disney.
                  <br />
                  <a href="https://disneyprivacycenter.com/privacy-policy-translations/english/">
                    https://disneyprivacycenter.com/privacy-policy-translations/english/
                  </a>
                </p>
              </div>
              {/* <ReCaptcha
                sitekey={captchaSiteKey}
                action="OurFirstShowForm"
                verifyCallback={this.onGoogleVerify}
              /> */}
            </>
          )}
        </div>
      </Container>
    )
  }
}

export default MusicPlayDayForm
